<template>
	<!-- 机构主页 -->
	<div class="page-all">
		<totop></totop>
		<contact ref='child'></contact>
		<div class="page-message">
			<img v-if="this.data.schoolInfo.school_banner==null" src="../../../assets/img/courseDetail/organizeBg.png" class="messageBg">
			<img v-else :src="data.schoolInfo.school_banner" class="messageBg">
			<!-- 机构基本信息 -->
			<div class="messageInfo">
				<el-row>
					<el-col class="infoItem">
						<div>{{data.userCount}}</div>
						<div class="itemTitle">学员数量</div>
					</el-col>
					<el-col class="infoItem itemLine">
						<div class="colLine"></div>
					</el-col>
					<el-col class="infoItem">
						<div>{{data.goodsCount}}</div>
						<div class="itemTitle">课程数量</div>
					</el-col>
					<el-col class="avatarItem">
						<img :src="data.schoolInfo.school_logo" class="organizeAvatar">
						<div class="organizeName">{{data.schoolInfo.school_name}}</div>
						<div class="organizeBtn">
							<div>活动</div>
							<div @click="getDiscount()">领优惠券</div>
						</div>
						<!-- 优惠券窗口 -->
						<div class="organizeDiscount" v-show="this.discount==true">
							<div class="discountTitle">限时优惠，可领取该机构课程的优惠券</div>
							<div v-for="item in couponData">
								<div class="line"></div>
								<div class="discountContent">
									<div class="contentUp">
										<span>￥{{item.discount}}</span>
										<span>{{item.coupon_name}}</span>
										<span v-show="item.is_get==0" @click="toGetCoupon(item.id)">领取</span>
										<span v-show="item.is_get==1">已领取</span>
									</div>
									<div class="contentDown">
										有效期至：{{item.end_time}}
									</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col class="infoItem">
						<div>{{data.schoolRank}}分</div>
						<div class="itemTitle">机构评分</div>
					</el-col>
					<el-col class="infoItem itemLine">
						<div class="colLine"></div>
					</el-col>
					<el-col class="infoItem" style="margin-right: 0;">
						<img @click="showchat" class="organmess" src="../../../assets/img/courseDetail/console.png">
						<div @click="showchat" class="itemTitle">信息咨询</div>
					</el-col>
				</el-row>
			</div>
			<!-- 机构详细信息 -->
			<div class="messageContent">
				<el-menu :default-active="activeIndex" class="el-menu-demo menu" mode="horizontal" @select="handleSelect"
				 text-color="#333333">
					<el-menu-item index="1">主页</el-menu-item>
					<el-menu-item index="2">课程</el-menu-item>
					<el-menu-item index="3">讲师</el-menu-item>
					<el-menu-item index="4">关于我们</el-menu-item>
				</el-menu>
				<div class="line"></div>
			</div>
			<!-- 导航栏下的内容 -->
			<div class="detailContent">
				<!-- 主页 -->
				<div v-show="this.activeIndex=='1'">
					<div class="detailTitle">金牌讲师</div>
					<div>
						<el-row :gutter="100">
							<el-col :span="12" v-for="(item,index) in data.teachers" style="margin-bottom: 50px;">
								<div class="homeTeacher">
									<div class="homeTeacherLeft">
										<img class="leftAvatra" :src="item.avatarurl">
										<div class="leftSchool">{{item.graduate_school}}</div>
									</div>
									<div class="homeTeacherRight">
										<div>{{item.nickname}}</div>
										<div>
											课程 {{item.goods_count}}
											<span style="margin:10px">|</span>
											人气 {{item.order_count}}
										</div>
										<div>{{item.info}}</div>
									</div>
								</div>
							</el-col>
						</el-row>
						<div class="detailTitle">精品课程</div>
						<el-row>
							<el-col :span="6" v-for="(item,index) in data.goods" style="margin-bottom: 40px;">
								<div class="homeCourse" @click="todetail(item.id)">
									<img class="coursePic" :src="item.goods_img">
									<div class="courseTitle">{{item.goods_name}}</div>
									<div class="coursePrice">
										<span class="priceOne" v-show="item.is_free==0">￥{{item.goods_price}}</span>
										<span class="priceOne" v-show="item.is_free==1">免费</span>
										<span class="priceVip" v-show="item.is_vip==1">VIP免费</span>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>

				<!-- 课程 -->
				<div v-show="this.activeIndex=='2'">
					<div class="detailTitle">课程列表</div>
					<div class="courseMenu">
						<div v-for="(item,index) in menuList" :class="['menuTitle',chooseMenu==item.id?'menuTitleIn':'']" @click="courseMenu(item.id)">{{item.name}}</div>
						<div class="colLine1"></div>
						<el-checkbox v-model="is_good" @change="goodsChange()" style="margin-top: 16px;">好评</el-checkbox>
						<el-checkbox v-model="is_hot" @change="goodsChange()" style="margin-top: 16px;">畅销</el-checkbox>
					</div>
					<div class="courseContent">
						<div :class="['courseItem',num.includes(index+1)?'courseItemFifth':'']" v-for="(item,index) in courseData.data"
						 @click="todetail(item.id)">
							<img class="coursePic" :src="item.goods_img">
							<div class="courseTitle">{{item.goods_name}}</div>
							<div class="coursePrice">
								<span class="priceOne" v-show="item.is_free==0">￥{{item.goods_price}}</span>
								<span class="priceOne" v-show="item.is_free==1">免费</span>
								<span class="priceVip" v-show="item.is_vip==1">VIP免费</span>
							</div>
						</div>
					</div>
					<!-- 分页 -->
					<div class="pagination">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
						 background layout="prev, pager, next" :total="total"></el-pagination>
					</div>
				</div>

				<!-- 讲师 -->
				<div v-show="this.activeIndex=='3'">
					<div class="detailTitle">金牌讲师</div>
					<el-row :gutter="100">
						<el-col :span="12" v-for="(item,index) in teachData.data" style="margin-bottom: 50px;">
							<div class="homeTeacher">
								<div class="homeTeacherLeft">
									<img class="leftAvatra" :src="item.avatarurl">
									<div class="leftSchool">{{item.graduate_school}}</div>
								</div>
								<div class="homeTeacherRight">
									<div>{{item.nickname}}</div>
									<div>
										课程 {{item.goods_count}}
										<span style="margin:10px">|</span>
										人气 {{item.order_count}}
									</div>
									<div>{{item.info}}</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>

				<!-- 讲师 -->
				<div v-show="this.activeIndex=='4'">
					<div class="detailTitle">机构概述</div>
					<div class="detailText" v-html="data.schoolInfo.school_info"></div>
				</div>

				<!-- 虚拟div 占位 -->
				<div style="width: 100%;height: 100px;"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import contact from '../../common/contact.vue';
	import totop from '../../common/totop.vue';
	export default {
		components: {
			contact,
			totop
		},
		data() {
			return {
				// 导航栏(主页)
				activeIndex: '1',
				// 菜单(课程)
				chooseMenu: 1,
				menuList: [{
						id: 1,
						name: "全部"
					},
					{
						id: 2,
						name: "免费"
					},
					{
						id: 3,
						name: "VIP课程"
					},
				],
				// 5的倍数的数
				num: [],
				// 优惠券
				discount: false,
				couponData: {},
				// 接受到的id
				resId: 1,
				// 获取到的总数据
				data: {},
				// 获取到课程的数据
				courseData: {},
				// 好评
				is_good: false,
				// 畅销
				is_hot: false,
				// 分页
				list_rows: 1,
				page: 1,
				currentPage: 1,
				total: 0,
				// 获取到讲师的数据
				teachData: {},
			};
		},
		watch: {
			'$route': function() {
				this.resId = this.$route.query.id
				this.getData()
				this.getCourseData()
				this.getTeacherData()
				this.getCoupon()
				this.toCompute()
			},
		},
		created() {
			this.resId = this.$route.query.id
			this.getData()
			this.getCourseData()
			this.getTeacherData()
			this.getCoupon()
			this.toCompute()
		},
		methods: {
			// 获取协议内容
			getData() {
				this.$get('school/schoolindex', {
					school_id: this.resId
				}).then(response => {
					if (response.error == 0) {
						this.data = response.data;
					} else {
						this.$router.push({
							path: "/home"
						})
						this.$message.error(response.msg);
					}
				});
			},

			// 机构课程列表
			getCourseData() {
				this.$get('school/schoolgoodslist', {
					school_id: this.resId,
					type: this.chooseMenu,
					is_good: this.is_good,
					is_hot: this.is_hot,
					page: this.page,
					list_rows: this.list_rows,
				}).then(response => {
					if (response.error == 0) {
						this.total = response.data.goodsList.total;
						this.courseData = response.data.goodsList;
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 机构讲师列表
			getTeacherData() {
				this.$get('school/schoolteacherlist', {
					school_id: this.resId,
				}).then(response => {
					if (response.error == 0) {
						this.teachData = response.data.teacherList;
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 获取优惠券列表
			getCoupon() {
				this.$get('goods/getcoupon/' + this.resId).then(response => {
					if (response.error == 0) {
						this.couponData = response.data;
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 领取优惠券
			toGetCoupon(which) {
				this.$post('goods/addcoupon/', {
					school_id: this.resId,
					coupon_id: which
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg);
						this.getCoupon()
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 表格每页条数的变化
			handleSizeChange(e) {
				this.list_rows = e
				this.getCourseData()
			},

			// 当前页的变化
			handleCurrentChange(e) {
				this.page = e
				this.getCourseData()
			},

			// 导航栏的选择按钮
			handleSelect(key) {
				this.activeIndex = key
			},

			// 选择菜单
			courseMenu(which) {
				this.chooseMenu = which
				this.getCourseData()
			},
			goodsChange() {
				this.getCourseData()
			},
			//课程详情
			todetail(e) {
				if (!localStorage.getItem('token')) {
					this.$refs.child.toshow(1);
					return
				}
				this.$router.push({
					path: '/courseDetail',
					query: {
						id: e
					}
				})
			},
			// 处理课程列表的元素
			toCompute() {
				for (var i = 0; i < this.courseData.data.length; i++) {
					if (i % 5 == 0 && i != 0) {
						this.num.push(i)
					}
				}
			},

			// 领取优惠券
			getDiscount() {
				if (this.discount == true) {
					this.discount = false
				} else {
					this.discount = true
				}
			},
			showchat() {
				this.$refs.child.toshow(this.resId)
			}

		}
	};
</script>

<style>
	.page-all {
		width: 100%;
		height: auto;
		background-color: white;
		position: relative;
		top: -1px;
	}

	.page-message {
		width: 72%;
		height: auto;
		margin: 0 auto;
	}

	.messageBg {
		width: 100%;
		height: 334px;
		position: relative;
		z-index: 0;
	}

	.messageInfo {
		width: 810px;
		height: 200px;
		position: absolute;
		margin-left: 14%;
		margin-top: -80px;
	}

	.infoItem {
		width: 100px;
		height: auto;
		font-size: 24px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #FFFFFF;
		text-align: center;
		margin-right: 30px;
	}

	.itemLine {
		width: 2px;
		margin-top: 4px;
	}

	.colLine {
		width: 1px;
		height: 46px;
		background: #FFFFFF;
	}

	.colLine1 {
		width: 1px;
		height: 28px;
		background: #E6E6E6;
		margin-top: 14px;
		margin-right: 30px;
	}

	.itemTitle {
		font-size: 14px;
	}

	.avatarItem {
		width: 230px;
		height: auto;
		margin-right: 25px;
		text-align: center;
	}

	.organizeAvatar {
		width: 116px;
		height: 117px;
		border-radius: 10px;
		/* background-color: #000000; */
		margin: 0 auto;
	}

	.organizeName {
		width: 230px;
		height: auto;
		font-size: 22px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #333333;
		line-height: 31px;
		margin-top: 16px;
	}

	.organizeBtn {
		width: 130px;
		height: 30px;
		margin: 0 auto;
		margin-top: 7px;
		cursor: pointer;
	}

	.organizeBtn div:first-child {
		display: inline-block;
		width: 40px;
		height: 24px;
		background: #56D2BF;
		border-radius: 5px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 24px;
		text-align: center;
		margin-right: 8px;
	}

	.organizeBtn div:last-child {
		display: inline-block;
		width: 71px;
		height: 24px;
		background: #F4586C;
		border-radius: 5px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 24px;
		text-align: center;
	}

	.organizeDiscount {
		width: 262px;
		height: 186px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
		position: relative;
		z-index: 1;
		margin-left: -15px;
		overflow-y: auto;
		text-align: left;
	}

	.discountTitle {
		width: 246px;
		height: 30px;
		line-height: 30px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F4586C;
		margin: 10px;
	}

	.discountContent {
		width: 212px;
		height: auto;
		margin: 10px;
	}

	.contentUp {
		width: 240px;
		height: 30px;
	}

	.contentUp span:nth-child(1) {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #F4586C;
	}

	.contentUp span:nth-child(2) {
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
	}

	.contentUp span:nth-child(3) {
		width: 46px;
		height: 20px;
		background: #FFFFFF;
		border: 1px solid #F4586C;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F4586C;
		line-height: 20px;
		text-align: center;
		float: right;
	}

	.contentUp span:nth-child(4) {
		width: 46px;
		height: 20px;
		background: #FFFFFF;
		border: 1px solid #999999;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		line-height: 20px;
		text-align: center;
		float: right;
	}

	.contentDown {
		font-size: 13px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
	}

	.messageContent {
		width: 100%;
		height: auto;
		margin-top: 140px;
	}

	.menu {
		width: 500px;
		margin: 0 auto;
	}

	.line {
		width: 100%;
		height: 1px;
		background: #E6E6E6;
	}

	.detailContent {
		width: 100%;
		height: auto;
		margin-top: 40px;
	}

	.detailTitle {
		font-size: 24px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-bottom: 20px;
	}

	.homeTeacher {
		width: 500px;
		height: 148px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 20px 20px;
	}

	.homeTeacher:hover {
		box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.homeTeacherLeft {
		width: 110px;
		height: auto;
		text-align: center;
		margin-right: 22px;
		float: left;
	}

	.leftAvatra {
		width: 110px;
		height: 110px;
		border-radius: 15px;
		/* background-color: #000000; */
	}

	.leftSchool {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}

	.homeTeacherRight {
		display: inline-block;
		width: 360px;
		height: auto;
		margin-top: 15px;
	}

	.homeTeacherRight div:nth-child(1) {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #181818;
	}

	.homeTeacherRight div:nth-child(2) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		margin-top: 8px;
	}

	.homeTeacherRight div:nth-child(3) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		margin-top: 16px;
	}

	.homeCourse {
		width: 270px;
		height: 316px;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		border-radius: 5px;
	}

	.coursePic {
		width: 100%;
		height: 200px;
		border-radius: 5px 5px 0px 0px;
	}

	.courseTitle {
		width: auto;
		height: 40px;
		font-size: 16px;
		overflow: hidden;
		white-space: nowrap;
		/*内容超宽后禁止换行显示*/
		text-overflow: ellipsis;
		/*文字超出部分以菌棍距省略号显示*/
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 24px;
		margin: 0 10px;
		margin-top: 14px;
	}

	.coursePrice {
		margin-top: 18px;
		margin-left: 4px;
	}

	.priceOne {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
		margin-right: 5px;
	}

	.priceVip {
		display: inline-block;
		width: auto;
		min-width: 48px;
		max-width: 60px;
		height: 18px;
		background: #E0BD59;
		border-radius: 3px;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 18px;
		text-align: center;
	}

	.courseMenu {
		width: 100%;
		height: 54px;
		background: #F6F6F6;
		display: flex;
		flex-wrap: nowrap;
		margin-bottom: 20px;
	}

	.menuTitle {
		width: auto;
		height: 54px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 54px;
		text-align: center;
		cursor: pointer;
		padding: 0 30px;
	}

	.menuTitleIn {
		width: auto;
		height: 54px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: white;
		background-color: #00D0B0;
		line-height: 54px;
		text-align: center;
		padding: 0 30px;
	}

	.courseContent {
		width: 100%;
		height: auto;
		display: flex;
		flex: 5;
		flex-wrap: wrap;
	}

	.courseItem {
		width: 222px;
		height: 316px;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		border-radius: 5px;
		margin-right: 23px;
		margin-bottom: 23px;
		cursor: pointer;
	}

	.courseItemFifth {
		margin-right: 0px;
	}

	.pagination {
		width: 100%;
		height: auto;
		text-align: center;
	}

	.detailText {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		margin-bottom: 300px;
	}

	/* 导航栏激活菜单的颜色 */
	.el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: 4px solid #00D0B0 !important;
		color: #303133 !important;
	}

	.el-menu--horizontal>.el-menu-item {
		margin: 0 20px;
	}

	/* 多选框激活的样式 */
	.el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #00D0B0 !important;
		border-color: #00D0B0 !important;
	}

	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #333333;
	}

	/* 分页 */
	.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #00D0B0 !important;
		color: #FFF;
	}

	i {
		margin-top: 0;
	}
	.organmess{
		width: 26px;
		cursor: pointer;
		height: 24px;
	}
</style>
